<template>
  <div class="pageMain">
    <div class="map">
      <baidu-map
        :center="center"
        :zoom="zoom"
        class="mapbox"
        :scroll-wheel-zoom="true"
        @ready="bMapLoad"
      >
        <div class="projectBox">
          <div>
            <el-select
              v-model="value"
              filterable
              placeholder="请选择"
              class="block-select"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="titles" @click="getProject">进入项目</div>
        </div>
        <!-- <div class="back" @click="to">后台系统</div> -->
        <div class="modele">
          <div v-for="(itemLabel, index) in menuList" :key="index">
            <div class="titleBox" @click="go(itemLabel)">
              {{ itemLabel.label }}
            </div>
          </div>
        </div>
        <projectTotal class="echarts-container"></projectTotal>
      </baidu-map>
    </div>
  </div>
</template>

<script>
import monitor from "../../../api/modules/monitor";
import * as echarts from "echarts";
import projectTotal from "../../../components/monitor/projectTotal.vue";
import marksImg from "../../../assets/images/screen/marks.png";
import marks2Img from "../../../assets/images/screen/marks2.png";
import marks3Img from "../../../assets/images/screen/marks3.png";
let mapConstructor;
export default {
  components: { projectTotal },
  data() {
    return {
      center: { lng: 111.52553, lat: 36.093742 },
      zoom: 15,
      markerArr: [],
      map: null, // 地图容器
      BMap: null, // 构造器容器
      options: [],
      value: "",
      menuList: [
        { label: "实时监控", value: 0 },
        { label: "扬尘排行榜", value: 1 },
        { label: "监测预警", value: 2 },
        { label: "设备统计", value: 3 },
        { label: "后台系统", value: 4 },
      ],
    };
  },
  mounted() {
    this.getProjectMapLists();
  },
  methods: {
    // BMap加载触发实例化方法
    bMapLoad({ BMap, map }) {
      // 百度地图容器全局化
      this.map = map;
      // 百度地图构造函数全局化
      mapConstructor = BMap;
      this.BMap = BMap;

      this.initMap();
      // this.openInfoWindows();
    },
    initMap() {
      this.addMapControl(); //向地图添加控件
      this.bMapPoint(); //向地图中添加marker
    },
    //向地图添加控件
    addMapControl() {
      //向地图中添加缩放控件
      var ctrl_nav = new mapConstructor.NavigationControl({
        anchor: BMAP_ANCHOR_TOP_LEFT,
        type: BMAP_NAVIGATION_CONTROL_LARGE,
      });
      this.map.addControl(ctrl_nav);
      //向地图中添加缩略图控件
      var ctrl_ove = new mapConstructor.OverviewMapControl({
        anchor: BMAP_ANCHOR_BOTTOM_RIGHT,
        isOpen: true,
      });
      this.map.addControl(ctrl_ove);
      //向地图中添加比例尺控件
      var ctrl_sca = new mapConstructor.ScaleControl({
        anchor: BMAP_ANCHOR_BOTTOM_LEFT,
      });
      this.map.addControl(ctrl_sca);
    },
    // 在百度地图上打点方法
    async bMapPoint() {
      let that = this; // 用与下面点击打点进行路由跳转，因为找不到this
      // 清除地图上的覆盖物
      this.map.clearOverlays();
     
      await monitor.getInfoConstruction().then((res) => {
        console.log(res, "xxxc");
        // this.options = [];

        // res.data.map((items) => {
        //   this.options.push({
        //     label: items.projName,
        //     value: items.projId,
        //   });
        //   if (items.projState == 0) {
        //     items.projState = "正常";
        //   } else if (items.projState == 1) {
        //     items.projState = "停工";
        //   } else {
        //     items.projState = "完结";
        //   }
        // });
        this.markerArr = res.data;
      });
      // return
      this.markerArr.forEach((item) => {
        const mPoint = new mapConstructor.Point(item.projLong, item.projLot);
        let myIcon;
        if (item.constPm10 < 70) {
          myIcon = marksImg;
        } else if (item.constPm10 < 101&&item.constPm10 >71) {
          myIcon = marks2Img;
        } else {
          myIcon = marks3Img;
        }
        const myIconInstance = new BMap.Icon(myIcon, new BMap.Size(47, 52));
        // const myIcon = new BMap.Icon(
        //   require("../../../assets/images/screen/marks.png"),
        //   new BMap.Size(47, 52)
        // );
        //   var marker = new mapConstructor.Marker(mPoint,{ icon: myIcon });
        var marker = new mapConstructor.Marker(mPoint, {
          icon: myIconInstance,
        });
        marker.setAnimation(BMAP_ANIMATION_BOUNCE); //给标点处增加动态效果
        var label = new mapConstructor.Label(
          `&nbsp;&nbsp;&nbsp;&nbsp;PM10：&nbsp;${item.constPm10}</br>${item.projName}`,
          {
            offset: new mapConstructor.Size(30, -10),
          }
        );
        // 设置 label 样式
        label.setStyle({
          padding: "10px 20px 10px 5px",
          backgroundColor: "#fff",
          color: "#2d9c5c",
          borderColor: "#2d9c5c",
          borderRadius: "5px",
          // marginRight:'10px'
        });
        marker.setLabel(label);

        var info = new mapConstructor.InfoWindow(
          "<p style='font-size:12px;line-height:1.8em; '>" +
            item.projName +
            "</br>设备名称：" +
            item.deviceId +
            "</br>PM10：" +
            item.constPm10 +
            "</p>"
        ); //悬浮提示信息
        marker.addEventListener("mouseover", function () {
          this.openInfoWindow(info); //悬浮监听提示方法
        });
        marker.addEventListener("click", function () {
          that.$router.push({
            path: "/view/monitor/deviceSelect",
            query: {
              projId: item.projId,
            },
          });
        });
        this.map.addOverlay(marker);
      });
    },

    getProject() {
      if (this.value) {
        this.$router.push({
          path: `/view/monitor/deviceTotalProject`,
          query: {
            projId: this.value,
          },
        });
      }
    },

    go(item) {
      const paths = {
        0: "/view/monitor/realMonitor",
        1: "/view/monitor/fugitiveRank",
        2: "/view/monitor/alarm",
        3: "/view/monitor/deviceTotal",
        4: "/view/monitor/deviceSelect",
      };

      // 确保 item.value 在 paths 对象中
      if (paths[item.value] !== undefined) {
        this.$router.push({
          path: paths[item.value],
          query: {
            projId: this.value,
          },
        });
      } else {
        console.error("Invalid item value:", item.value);
      }
    },
    getProjectMapLists() {
   
      monitor.getProjectMapList().then((res) => {
        this.options = [];

        res.data.map((items) => {
          this.options.push({
            label: items.projName,
            value: items.projId,
          });
          // if (items.projState == 0) {
          //   items.projState = "正常";
          // } else if (items.projState == 1) {
          //   items.projState = "停工";
          // } else {
          //   items.projState = "完结";
          // }
        });
        // this.markerArr = res.data;
      });
    },
  },
};
</script>

<style scoped lang="less">
.map {
  height: 100%;
}
.mapbox {
  position: relative;
  height: 100%;
  width: 100%;
}
.echarts-container {
  position: absolute;
  right: 1%;
  top: 50%;
  transform: translate(-1%, -50%);
  height: 30%;
  width: 20%;
  background-color: #fff;
}
.projectBox {
  display: flex;
  align-items: center;
  position: absolute;

  left: 5%;
  top: 1%;
  transform: translate(-5%, -1%);
}
.back {
  position: absolute;
  right: 1%;
  top: 1%;
  transform: translate(-1%, -1%);
  // height: 30%;
  // width: 20%;
  background-color: #fff;
  padding: 10px 20px;
  // width: 100%;
  // margin-left: 10px;
  color: #008f4d;
  border-radius: 5%;
  cursor: pointer;
}
.back:hover {
  background-color: #008f4d;
  color: #fff;
}

.titles {
  background-color: #008f4d;
  padding: 8px 15px;
  color: #fff;
  margin-left: 5px;
  cursor: pointer;
}
.modele {
  display: flex;
  position: absolute;
  right: 2%;
  top: 1%;
  // flex-wrap: wrap;
  transform: translate(-2%, -1%);

  // height: 30%;
  // width: 20%;
  // background-color: #008f4d;
  // padding: 5px 15px;
  // color: #fff;
  // border-radius: 5%;
  cursor: pointer;
}
.titleBox {
  background-color: #fff;
  padding: 10px 20px;
  // width: 100%;
  margin-left: 10px;
  color: #008f4d;
}
.titleBox:hover {
  background-color: #008f4d;
  color: #fff;
}
</style>
