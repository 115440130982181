<!--  -->
<template>
  <div class="main-box">
    <div class="title">项目总数{{ count }}个</div>
    <div class="chart-box" ref="monthWarn"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { getProjectTotal } from "../../components/screen/chartOption";
import monitor from "../../api/modules/monitor";
export default {
  components: {},
  data() {
    return {
      chartMonthWarn: null,
      count:'',
    };
  },
  mounted() {
    this.initCarTrendChar();
  },
  methods: {
    initCarTrendChar() {
      monitor.getProjectCount().then((res) => {
        console.log(res, "个数");
        this.count =res.data.finishCount + res.data.runCount+res.data.stopCount
        let categoryData = ["完工", "在建", "停工"];
      let chartdata = [res.data.finishCount, res.data.runCount, res.data.stopCount];
      let backgroundColor = "#fff";
      let itemcolor = {
        type: "linear",
        colorStops: [
          {
            offset: 0,
            color: "rgba(6, 120, 157,1)",
          },
          {
            offset: 0.5,
            color: "rgba(6, 120, 157,0.2)",
          },
          {
            offset: 1,
            color: "rgba(6, 120, 157,1)",
          },
        ],
      };

      if (!this.chartMonthWarn) {
        this.chartMonthWarn = echarts.init(this.$refs.monthWarn, "chalk");
      }

      const option = getProjectTotal(categoryData, chartdata, backgroundColor,itemcolor);

      this.chartMonthWarn.setOption(option);
      });
     
    },
  },
};
</script>

<style lang="less" scoped>
.main-box {
  height: 100%;
  padding: 10px 0 0 10px;
  .chart-box {
    height: 90%;
    // width: 600px;
  }
}
.title{
    font-weight: bold;
}
</style>
